import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LogoutIcon from '@mui/icons-material/Logout';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Logo from '../img/mainLogo.png'
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@mui/material';
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
function SideBar ({children}){
    async function onLogout() {
        await signOut();
        window.location.href = "/";
      }
    const menuItem=[
        
        {
            path:'/home',
            name:'Home',
            icon:<HomeIcon></HomeIcon>
        },
        {
            path:'/orders',
            name:'Siparişler',
            icon:<BorderColorIcon></BorderColorIcon>
        },
        {
            path:'/orderspage',
            name:'Sipariş Kayıtları',
            icon:<BorderColorIcon></BorderColorIcon>
        },
        {
            path:'/menus',
            name:'Menüler',
            icon:<CategoryIcon></CategoryIcon>
        },
        {
            path:'/category',
            name:'Kategoriler',
            icon:<ApartmentIcon></ApartmentIcon>
        },
        {
            path:'/items',
            name:'Ürünler',
            icon:<MenuBookIcon></MenuBookIcon>
        },
        
       
    ]
    const [isOpen,setOpen]=useState(false)
    const toggle =()=> setOpen(!isOpen)
    return(
        <div className='container'>
            <div className='sidebar' style={{width:isOpen?'300px':'50px'}}>
                <div className='top_section'>
                   <div style={{display:isOpen?'block':'none',width:200}} className='logo'><img style={{maxWidth:200}} src={Logo}alt="" /></div>
                   <div className="bars" style={{marginLeft:isOpen?'50px':'0px'}}><MenuIcon onClick={toggle}></MenuIcon></div>
                </div>
                {
                menuItem.map((item,index)=>(
                    <NavLink to={item.path} key={index} className='link' activeclassname='active'>
                        <div className="icon">{item.icon}</div>
                        <div  style={{display:isOpen?'block':'none'}} className="link_text">{item.name}</div>
                    </NavLink>
                    
                ))
                
                
                }
     <div onClick={onLogout} style={{ position: 'fixed', bottom: 0, left: 0, width: isOpen ? '300px' : '50px' }}>
                <NavLink to="/logout" className="link" >
                    <div className="icon">
                        <LogoutIcon style={{ color: 'white' }} />
                    </div>
                    <div on style={{ display: isOpen ? 'block' : 'none' }} className="link_text">
                        Çıkış Yap
                    </div>
                </NavLink>
            </div>
            </div>
            <main>{children}</main>
        </div>
    )
}
export default SideBar