import { Box, Button,  Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";


export default function MenuCreateForm({open,handleClose,id,errorFound,noError,searchData}){
       
    const [name,setName]= useState("")
    const [discription,setDiscription]=useState("")
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid gray',
        borderRadius:5,
        boxShadow: 12,
        p: 4,
      };
      const config = {
        headers:{
          'Content-type': 'application/json'
        }
      };
      const fetchData = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_NGROK+"/menu/"+id,{
            headers:{
              
            },
          });
          
          setName(response.data.name);
          setDiscription(response.data.description)
        } catch (error) {
          
        }
      };

      useEffect(() => {
        if(id){
          fetchData();
        }
      }, [id]);
      async function handleSubmit(event) {
        event.preventDefault();
    
        try {
            let formData = { name: name, description: discription, categories: [3] };
            await axios.patch(process.env.REACT_APP_NGROK + "/menu/" + id, JSON.stringify(formData), config);
    
            // İşlem başarılı olduysa
            noError();
            setName('');
            setDiscription('');
            handleClose();
    
            // searchData() fonksiyonunu burada çağırabilirsiniz
            searchData();
        } catch (error) {
            // Hata oluştuysa
            errorFound();
        }
    }
    return(
<>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <Typography sx={{mb:4,fontWeight:'Bold'}}>Menu Güncelleme Formu</Typography>
            <form onSubmit={handleSubmit} >
                <TextField
                    type="text"
                    variant='outlined'
                    color='secondary'
                    label="Menü Adı"
                    onChange={e => setName(e.target.value)}
                    value={name}
                    fullWidth
                    required
                    sx={{mb: 4}}
                />
                <TextField
                    type="text"
                    variant='outlined'
                    color='secondary'
                    label="Menü Açıklaması"
                    onChange={e => setDiscription(e.target.value)}
                    value={discription}
                    fullWidth
                    required
                    helperText="Açıklama 10 karakter ve üzeri olmak zorundadır"
                    sx={{mb: 4}}
                />
                <Button fullWidth variant="contained" color="primary" type="submit">Menü Güncelleme</Button>
            </form>
   
  </Box>
</Modal></>
    )
}
