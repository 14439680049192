
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, Typography } from '@mui/material';
function DateSelectBox({onDataIdChange}){
const [value, setValue] = React.useState(null);
const handleChange = (selectedDate) => {
    setValue(selectedDate);
    onDataIdChange(selectedDate);
  };
return(
    <FormControl variant="standard" fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography>Tarih Seçiniz</Typography>
        <DatePicker value={value} onChange={handleChange} />
      </LocalizationProvider>
    </FormControl>
)
}
export default DateSelectBox