import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MenuSelectBox = forwardRef((props, ref) => {
  const { onMenuIdChange, selectedMenuId } = props;

  const [menuData, setMenuData] = useState([]);
  const [menuId, setMenuId] = useState(selectedMenuId || "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_NGROK + "/menu", {
          headers: {
           // 'ngrok-skip-browser-warning':'asd'
          },
        });
        setMenuData(response.data);
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error);
      }
    };

    fetchData();
  }, []); // Boş bağımlılıklar dizisi

  const handleChange = (event) => {
    const selectedMenuId = event.target.value;
    setMenuId(selectedMenuId);
    onMenuIdChange(selectedMenuId);
  };

  useImperativeHandle(ref, () => ({
    clearMenuId: () => clearMenuId(),
  }));

  const clearMenuId = () => {
    setMenuId("");
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ width: '100%', mb: 2 }}>
        <InputLabel id="demo-simple-select-label">Menu Seçiniz</InputLabel>
        <Select
          sx={{ width: '100%', mb: 4 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          autoWidth
          required
          value={menuId}
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {menuData.length > 0 ? (
            menuData.map((menu) => (
              <MenuItem key={menu.id} value={menu.id}>
                {menu.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Veri yükleniyor...</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
});

export default MenuSelectBox;