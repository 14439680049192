import {Typography } from "@mui/material"
import { Container } from "@mui/system"
import { Box } from "@mui/system"
import React, { useEffect } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import axios from "axios";


function Home (){
    //Profil İnfo
    
    const [personData, SetPersonData] = React.useState([]);
    const [establisment,setEstablistment] = React.useState([]);
    useEffect(() => {
      fetchData()
    },[]);
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_NGROK+'/establishment/self', {
          headers:{
            
          },
          params: {
          
          }
        });
        if(response.data){
          SetPersonData(response.data);
          if(response.data.Brand.id){
            setEstablistment(response.data.Brand)
          }
           
        }
        else{
          console.log('Category Boş')
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };  
      const headStyle={
        fontSize:20,
        fontWeight:'Bold',
        mt:2
      }

    const sessionContext = useSessionContext();
    return(
        
        <Container maxWidth="xl" sx={{backgroundColor:'white'  ,height: '100vh'}}>
        <Box mt={6} sx={{ p: 2, borderBottom: '1px outset grey' }}>
            <Typography variant="h3">Profil Ekranı </Typography>
        </Box>
        <Box mt={6} sx={{ p: 2, borderBottom: '1px outset grey' }}>
          
        {sessionContext.userId? <div style={{maxWidth:400}} variant="p"><img style={{maxWidth:400}} src={establisment.logo} alt="" /></div> :<Typography variant="p">Kayıtlı değilsin lütfen bilgilerini update le</Typography>}
        </Box>
        <Box mt={6} sx={{ p: 2, borderBottom: '1px outset grey' }}>
          <Typography sx={headStyle}>Şirket Ünvanı: {establisment?<>{establisment.name}</>:<></>}</Typography>
          <Typography sx={headStyle}>Mail Adresi: <a href={personData.email}>{personData.email}</a></Typography>
          <Typography sx={headStyle}>Menü: {establisment?<>{establisment.website}</>:<></>}</Typography>
        </Box>
        
        </Container>
    )
}
export default Home