import { Box, Button, Container, Grid, Typography } from "@mui/material";
import '../App.css'
import React, { useEffect, useState } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import CategoryUpdateForm from "../components/forms/category/CategoryUpdateForm";
import CategoryCreateForm from '../components/forms/category/CategoryCreateForm'
import DataTable from "../components/Datatable";
import MenuSelectBox from "../components/MenuSelectBox";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
function Category (){

        //Take Data
        const sessionContext = useSessionContext();
        const [permissions, setPermission] = useState([]);
        const [permissionSet, setPermissionSet] = useState(false);
        const navigate = useNavigate();
        const [newItem,setNewItem]=useState(false)
        const [menuCheck,setMenuCheck]=useState(true)
        const menuSelectBoxRef = useRef(null);
        const roleCheckDis = async ()=>{
          try {
              const response = await  sessionContext.accessTokenPayload['st-perm'].v
              await setPermission(response)
              setPermissionSet(true);
            } catch (error) {
             
            }
          
        }
        const getData=()=>{
          if(selectedMenuId){
            fetchData()
          }
          else{
            fetchDataWithoutId()
          }
        }
        const fetchData = async () => {
          try {
            const response = await axios.get(process.env.REACT_APP_NGROK+'/menu/'+selectedMenuId, {
              headers:{
               // 'ngrok-skip-browser-warning':'asd'
              },
              params: {
               
              }
            });
            if(response.data){
              if(response.data.Categories){
                setCategoryData(response.data.Categories);
              }
              else{
                setCategoryData(response.data);
              }
            }
            else{
              console.log('Category Boş')
            }
          } catch (error) {
            
          }
        };
        const fetchDataWithoutId = async () => {
          if(menuCheck){
          try {
            const response = await axios.get(process.env.REACT_APP_NGROK+'/category/', {
              headers:{
               // 'ngrok-skip-browser-warning':'asd'
              },
              params: {
                
              }
            });
            if(response.data){
               
               setCategoryData(response.data);
            }
            else{
              console.log('Category Boş')
            }
          } catch (error) {
            
          }
          }
        };
        const fetchDataUseEffect = async () => {
          if(menuCheck){
          try {
            const response = await axios.get(process.env.REACT_APP_NGROK+'/category/', {
              headers:{
               // 'ngrok-skip-browser-warning':'asd'
              },
              params: {
                
              }
            });
            if(response.data){
               setCategoryData(response.data);
            }
            else{
              console.log('Category Boş')
            }
          } catch (error) {
            
          }
          }
        };
        useEffect(() => {
          if (permissionSet) {
              if (!permissions.includes('read:category')) {
                  navigate('/home');
              }
          }
      }, [permissions, permissionSet]);
        useEffect(() => {
          roleCheckDis()
          if(newItem){

          }
          fetchDataUseEffect();
        },[permissions]);
    //DATAS
    const [selectedMenuId, setSelectedMenuId] = useState("");
    const [categoryData, setCategoryData] = React.useState([]);
    const colums = [
        { field: 'id',headerName:'ID',width:200 , headerAlign: 'center',  align:'center'},
        { field: 'name' ,headerName:'Kategori adı',width:200,headerAlign: 'center',align:'center'},
        { field: 'description',headerName:'Kategori açıklaması',width:500,headerAlign: 'center',align:'center'},
        {
            field: "action",
            width:250 ,
            align:'center',
            headerName: "Action",
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) =>
           <Button onClick={() => handleOpen(row)}><EditIcon/></Button> 
          },
          {
            field: "delete",
            width:250 ,
            align:'center',
            headerName: "Delete",
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) =>
            <Button onClick={() => deleteFunction(row.id)}><RemoveIcon></RemoveIcon></Button>
          },
      ]
    
    //Functions
    const [openNew, setOpenNew] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [id,setCategory] = React.useState(0);
    const errorFound =()=>{
      toast.error('Bir Hata ile Karşılaşıldı', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    const noError = ()=>{
      toast.success('Kategori Oluşturuldu!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    const handleClose = () => {
      setOpen(false)
      fetchDataWithoutId()
      
    };
    const handleOpen =(e)=>{
        setOpen(true)
        setCategory(e.id)
      }
      const deleteFunction = async (e) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_NGROK + `/category/` + e);
    
            if (response.error) {
                toast.error('Bir Hata ile Karşılaşıldı!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success('Menü Silindi!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            // Hata oluştuysa
        } finally {
            fetchData();
            searchData();
        }
    };
      const handleMenuIdChange = (menuId) => {
        setSelectedMenuId(menuId);
      };  
      const searchData =()=>{
        getData()
      }
      const clearData=()=>{
        setSelectedMenuId('')
        if (menuSelectBoxRef.current) {
          menuSelectBoxRef.current.clearMenuId();
        }
       
      }  
    const handleCloseNew = () =>{
      setOpenNew(false);
      searchData()
    } 
    const handleOpenNew =(e)=>{
        setOpenNew(true)
         
      }
    return(
        <>
        <Container maxWidth="xl" sx={{backgroundColor:"#f2f5f7",height: '100vh'}}>
        <Typography mt={3} variant="h3">Kategoriler</Typography>
        <div style={{height:2,width:500,backgroundColor:'#ffa500' ,marginTop:10,marginBottom:5}}></div>
        <Box mt={3} sx={{ p: 2,border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'  }}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
             <MenuSelectBox onMenuIdChange={handleMenuIdChange}  ref={menuSelectBoxRef}></MenuSelectBox>
            </Grid>
            <Grid item xs={4}>
                <div className="buttonGroup">
                {permissions.includes('update:menu')?<Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40 ,backgroundColor:'#217afa'}} onClick={handleOpenNew} variant="contained" >Yeni Kategori</Button>:<Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40 ,backgroundColor:'#217afa'}} onClick={handleOpenNew} variant="contained" >Yeni Menu</Button>}
                 <Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40 ,backgroundColor:'#386080'}} onClick={searchData} variant="contained" >Ara</Button>
                 <Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40,backgroundColor:'#fccc74'}} onClick={clearData}variant="contained" >Temizle</Button>
                </div>
        
            </Grid>

        </Grid>
        </Box>
        <Box mt={6} sx={{ p: 2,border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'  }}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <DataTable colums={colums} rows={categoryData}></DataTable>
            <CategoryUpdateForm open={open} id={id}handleClose={handleClose} searcData={getData} errorFound={errorFound} noError={noError}></CategoryUpdateForm>
            <CategoryCreateForm open={openNew} handleClose={handleCloseNew} searcData={getData} errorFound={errorFound} noError={noError}></CategoryCreateForm>
            </Grid>
        </Grid>
        </Box>
        <ToastContainer></ToastContainer>
        </Container>
        </>
    )
}
export default Category