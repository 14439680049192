import { Box, Button, Modal, TextField, Typography, Grid, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import MenuSelectBox from "../../MenuSelectBox";
import CategorySelectBox from "../../CategorySelectBox";
import axios from "axios";
import { useEffect } from "react";
import AlergenSelectBox from "../../AlergenSelectBox";

export default function FoodUpdateForm({ open, handleClose, id ,errorFound,noError}) {
  //Menu Array 
  const FormData = require("form-data");
  const form = new FormData();
  const [isAlcolic,setAlchol]= useState('')
  const [alergens,setAlergens]=useState([])
  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_NGROK+"/menu-item/"+id,{
        headers:{
          
        },
      });
      setAlergens(response.data.includesAllergen)
      setAlchol(response.data.isAlcoholic)
      setPrice(response.data.price)
      setName(response.data.name);
      setDiscription(response.data.description)
      setSelectedCategoryId(response.data.category.id)
      setSelectedFileImage(response.data.image)
      
    } catch (error) {
      
    }
  };
  useEffect(() => {
    if(id){
      fetchData();
    }
  }, [id]);
  const initialStateNameLan = {
    engName: "",
    ruName: "",
    almName: "",
  };
  const initialStateDiscriptionLan = {
    engDiscription: "",
    ruDiscription: "",
    almDiscription: "",
  };
  const [fileImage,setSelectedFileImage]=useState(null)
  const [stateNameLan, setStateNameLan] = useState(initialStateNameLan);
  const [stateNameDis, setStateNameDis] = useState(initialStateDiscriptionLan);
  
  const handleChangeLan = (name, value) => {
    setStateNameLan((prevState) => ({ ...prevState, [name]: value }));
    
  };
  const handleChangeDisLan = (name, value) => {
    setStateNameDis((prevState) => ({ ...prevState, [name]: value }));
  };
  const [urlS3,setUrl]=useState(null)
  const [fields,setFields]=useState(null)
  const [test,setTest]=useState(false)
  const [name, setName] = useState("")
  const [discription, setDiscription] = useState("")
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  let menuId = ''
  let categoryId = ''

  //Modals Const
  const [openDisCriptionLan, setDisOpenLan] = useState(false)

  const [openLan, setOpenLan] = useState(false)
  const handleAlergen = (alergen) => {
    setAlergens(alergen);
  };
  const handleMenuIdChange = (menuId) => {
    setSelectedMenuId(menuId);
  };
  const handleOpenLan = (e) => {
    setOpenLan(true)
  }
  const handleOpenDisLan = (e) => {
    setDisOpenLan(true)
  }
  const handleDisCloseLan = () => setDisOpenLan(false);
  const handleCloseLan = () => setOpenLan(false);
  const handleCategoyIdChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };
  const [price, setPrice] = useState('')
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid gray',
    borderRadius:5,
    boxShadow: 12,
    p: 4,
  };
  const config = {
    headers:{
      'Content-type': 'application/json'
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    Object.entries(fields).forEach(([field, value]) => {
      form.append(field, value);
    });
    form.append("file", selectedFile);
    axios.post(urlS3,form,{headers:{
      'Content-type': 'image/jpeg'
}}).then((response)=>{
     
    });

    
  }
  const s3DataGet =()=>{
    if(test){
     
    }
    else{
      setTest(true)
      axios.post(process.env.REACT_APP_NGROK+"/s3-bucket/upload",{fileName:'Kategori 2'},{headers:{
        'Content-type': 'application/json'
      }}).then((response)=>{
       setFields(response.data.fields)
       setUrl(response.data.url)
       setSelectedFileImage(response.data.uploadedTo)
      })
    }
  }
  async function handleSubmit(event) {
    event.preventDefault();

    try {
        const menuId = selectedMenuId;
        const categoryId = selectedCategoryId;

        const formData = {
            isAlcoholic:isAlcolic,
            image: fileImage,
            name: name,
            nameLocals: [
                { lang: 'en_US', field: stateNameLan.engName },
                { lang: 'ru_ru', field: stateNameLan.ruName },
                { lang: 'de_de', field: stateNameLan.almName }
            ],
            price: +price,
            description: discription,
            descriptionLocals: [
                { lang: 'en_US', field: stateNameDis.engDiscription },
                { lang: 'ru_ru', field: stateNameDis.ruDiscription },
                { lang: 'de_de', field: stateNameDis.almDiscription }
            ],
            category: [categoryId]
        };

        await axios.patch(process.env.REACT_APP_NGROK + '/menu-item/' + id, JSON.stringify(formData), config);

        noError();
        handleClose();
    } catch (error) {
        // Hata oluştuysa
        errorFound();
    }
}
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ mb: 1, fontWeight: 'Bold' }}>Ürün Kayıt Formu</Typography>
          <div style={{width:300,height:2,backgroundColor:'#192536',marginBottom:10,}}></div>
          <form onSubmit={handleSubmit} >
          <Grid container spacing={2}>
          <Grid item xs={6}>
            <MenuSelectBox  onMenuIdChange={handleMenuIdChange}> </MenuSelectBox>
            </Grid>
            <Grid item xs={6}>
            <CategorySelectBox  onMenuIdChange={handleCategoyIdChange} menuId={selectedMenuId}></CategorySelectBox>
            </Grid>
            
            <Grid item xs={6}>
            <TextField
              type="text"
              variant='outlined'
              color='secondary'
              label="Ürün Adı"
              onChange={e => setName(e.target.value)}
              value={name}
              fullWidth
              required
             
            />
            </Grid>
            <Grid item xs={6}>
            <Button sx={{ mb: 4, backgroundColor: '#386080' ,height:'55px'}} fullWidth variant="contained" color="primary" onClick={handleOpenLan}>Dil Ekle</Button>
            </Grid>
            <Grid item xs={6}>
            <TextField
              type="text"
              variant='outlined'
              color='secondary'
              label="Ürün Açıklaması"
              onChange={e => setDiscription(e.target.value)}
              value={discription}
              required
              fullWidth
              helperText="Açıklama 10 karakter ve üzeri olmak zorundadır"
             
            />
            </Grid>
            <Grid item xs={6}>
            <Button sx={{ mb: 4, backgroundColor: '#386080', height:'55px'}}  onClick={handleOpenDisLan} fullWidth variant="contained" color="primary" >Dil Ekle</Button>
            </Grid>
         
            <Grid item xs={6}>
              <Typography   gutterBottom>
              Ürününüz Alkol İçeriyormu ?
            </Typography>
            <Select
          fullWidth
          required
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={isAlcolic}
    label="Ürününüz Alkol İçeriyormu ?"
    onChange={e => setAlchol(e.target.value)}
  >
    <MenuItem value={true}>Evet</MenuItem>
    <MenuItem value={false}>Hayır</MenuItem>
  </Select>
  </Grid>
  <Grid item xs={6}>
            <Typography  gutterBottom>
              Ürününüz Alerjen İçeriyormu ?
            </Typography>
            <AlergenSelectBox  onSelectedOptionsChange={handleAlergen} ></AlergenSelectBox>

  </Grid>
  <Grid item xs={12}>
  <TextField
              type="number"
              variant='outlined'
              color='secondary'
              label="Fiyat"
              onChange={e => setPrice(e.target.value)}
              value={price}
              fullWidth
              required
              
            />
</Grid>
<Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" type="submit">Ürün Ekleme</Button>
            </Grid>
            <Grid item xs={12}>
            <Typography sx={{mt:3}} variant="h5" gutterBottom>
             Ürün Fotoğraf  Yükleme Formu
            </Typography>
            {test?<input
          style={{marginBottom:4}}
          accept="image/*" // Sadece belirli dosya türlerine izin vermek isterseniz burayı ayarlayabilirsiniz.
          type="file"
          onChange={handleFileChange}
        />:<></>}
        </Grid>
        </Grid>
          </form>
          <Button onClick={s3DataGet} sx={{mt: 4}} fullWidth variant="contained" color="primary" type="submit">Fotoğraf Ekle</Button>

        </Box>
        
      </Modal>
      <Modal
        open={openLan}
        onClose={handleCloseLan}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ mb: 4, fontWeight: 'Bold' }}>Ürün Adı Dil Çeviri Ekleme</Typography>
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="İngilizce Adı"
            onChange={e => handleChangeLan("engName", e.target.value)}
            value={stateNameLan.engName}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="Almanca Adı"
            onChange={e => handleChangeLan("almName", e.target.value)}
            value={stateNameLan.almName}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="Rusça Adı"
            onChange={e => handleChangeLan("ruName", e.target.value)}
            value={stateNameLan.ruName}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
        <Button fullWidth variant="contained" color="primary" onClick={handleCloseLan}>Onayla</Button>
        </Box>
      </Modal>
      <Modal
        open={openDisCriptionLan}
        onClose={handleDisCloseLan}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ mb: 4, fontWeight: 'Bold' }}>Ürün Açıklama Dil Çeviri Ekleme</Typography>
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="İngilizce Açıklaması"
            onChange={e => handleChangeDisLan("engDiscription", e.target.value)}
            value={stateNameDis.engDiscription}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="Almanca Açıklaması"
            onChange={e => handleChangeDisLan("almDiscription", e.target.value)}
            value={stateNameDis.almDiscription}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="Rusca  Açıklaması"
            onChange={e => handleChangeDisLan("ruDiscription", e.target.value)}
            value={stateNameDis.ruDiscription}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
        <Button fullWidth variant="contained" color="primary" onClick={handleDisCloseLan}>Onayla</Button>
        </Box>
      </Modal>
    </>
  )
}