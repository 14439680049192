import '../App.css'
import { Button, Typography,Grid } from "@mui/material"
import React, { useEffect } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { Container } from "@mui/system"
import { Box } from "@mui/system"
import DataTable from "../components/Datatable";
import MenuUpdateForm from "../components/forms/menu/MenuUpdateForm";
import MenuCreateForm from '../components/forms/menu/MenuCreateForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

function Menus () {
    const [permissionSet, setPermissionSet] = useState(false);
    const sessionContext = useSessionContext();
    const [permissions, setPermission] = useState([]);
    const navigate = useNavigate();
    const [menuCheck,setMenuCheck]=useState(true)
    const roleCheckDis = async ()=>{
      try {
          const response = await  sessionContext.accessTokenPayload['st-perm'].v
          await setPermission(response)
          setPermissionSet(true);
        } catch (error) {
         
        }
      
    }
    const activeMenu = (e)=>{
      let formData ={activeMenuId:e.id}
      axios.patch(process.env.REACT_APP_NGROK+'/establishment/self',formData).then((res)=>{
        if(res.error){
          
            toast.error('Bir Hata ile Karşılaşıldı', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          
       
        }else{
          toast.success('Menü Aktive Edildi', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      })
    }
    const activeOnlineMenu = (e)=>{
      let formData ={activeDeliveryMenuId :e.id}
      axios.patch(process.env.REACT_APP_NGROK+'/establishment/self',formData).then((res)=>{
        if(res.error){
          
            toast.error('Bir Hata ile Karşılaşıldı', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          
       
        }else{
          toast.success('Online Menü Aktive Edildi', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      })
    }
    const errorFound =()=>{
      toast.error('Bir Hata ile Karşılaşıldı', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    const noError = ()=>{
      toast.success('Menü Oluşturuldu!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_NGROK+"/menu",{
          headers:{
           // 'ngrok-skip-browser-warning':'asd'
          },
        });
        setMenuData(response.data);
      } catch (error) {
        
      }
    };
    const fetchDataUseEffect = async () => {
      if(menuCheck){
      try {
        const response = await axios.get(process.env.REACT_APP_NGROK+"/menu",{
          headers:{
           // 'ngrok-skip-browser-warning':'asd'
          },
        });
        setMenuData(response.data);
      } catch (error) {
        
      }}
    };
    useEffect(() => {
      if (permissionSet) {
          if (!permissions.includes('read:menu')) {
              navigate('/home');
          }
      }
  }, [permissions, permissionSet]);
    useEffect(() => {
      roleCheckDis()
      fetchDataUseEffect();
 
    }, []);
    //DATAS
    const [menuData, setMenuData] = React.useState([]);
    const colums = [
      {
        field: "setOnlineMenuId",
        width:350 ,
        align:'center',
        headerName: "Online Menü Aktif Et | Dükkan Menüsü Aktif Et",
        headerAlign: 'center',
        sortable: false,
        renderCell: ({ row }) =>
          <><Button onClick={() => activeOnlineMenu(row)}>
            <EditIcon/>
          </Button>
          <Button onClick={() => activeMenu(row)}>
          <EditIcon/>
        </Button></>,
      },
      { field: 'id', headerName: 'ID', width: 100, headerAlign: 'center', align: 'center', headerRender: () => null },
        { field: 'name' ,headerName:'Menu adı',width:200,headerAlign: 'center',align:'center'},
        { field: 'Categories' ,headerName:'Kategori Sayısı',width:200,headerAlign: 'center',align:'center', valueGetter: (params) => params.row.Categories.length.toString(),},
        { field: 'description',headerName:'Kategori açıklaması',width:200,headerAlign: 'center',align:'center'},
        {
            field: "action",
            width:150 ,
            align:'center',
            headerName: "Action",
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) =>
             <Button onClick={() => handleOpen(row)}><EditIcon/></Button>},
          {
            field: "delete",
            width:150 ,
            align:'center',
            headerName: "Delete",
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) =>
           <Button onClick={() => deleteFunction(row.id)}><RemoveIcon></RemoveIcon></Button>
          },
      ]
    const [openNew, setOpenNew] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [id,setId] = React.useState(0);
    const handleClose = () =>{
      setOpen(false)
      searchData()
    
    };
    const handleOpen =(e)=>{
        setOpen(true)
        setId(e.id)
      }
      const searchData =()=>{
        fetchData();
      }
      const deleteFunction = async (e) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_NGROK + `/menu/` + e);
    
            if (response.error) {
                toast.error('Bir Hata ile Karşılaşıldı!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success('Menü Silindi!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            // Hata oluştuysa
        } finally {
            fetchData();
            searchData();
        }
    };
    const handleCloseNew = () => {
      setOpenNew(false);
      searchData()
    }
    const handleOpenNew =(e)=>{
        setOpenNew(true)
        setId(e.id)
      }

    return(
        <Container maxWidth="xl" sx={{backgroundColor:'#f2f5f7'  ,height: '100vh'}}>

        <Typography mt={3} sx={{display:'none'}} variant="h3">{permissions}</Typography>
        <Typography mt={3} variant="h3">Menüler</Typography>
        <div style={{height:2,width:500,backgroundColor:'#ffa500'}}></div>
        <Box mt={3} sx={{ p: 2, border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
            
            </Grid>
            <Grid item xs={4}>
                <div className="buttonGroup">
                {permissions.includes('create:menu')?<Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40 ,backgroundColor:'#217afa'}} onClick={handleOpenNew} variant="contained" >Yeni Menu</Button>:<></>}
                 <Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40 ,backgroundColor:'#386080'}} onClick={searchData} variant="contained" >Ara</Button>
                </div>
        
            </Grid>

        </Grid>
        </Box>
        <Box mt={6} sx={{ p: 2, border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <DataTable colums={colums} rows={menuData}></DataTable>
            <MenuUpdateForm open={open} id={id}handleClose={handleClose} errorFound={errorFound} noError={noError} searchData={searchData} ></MenuUpdateForm>
            <MenuCreateForm open={openNew} handleClose={handleCloseNew} errorFound={errorFound} noError={noError} searchData={searchData}></MenuCreateForm>
            </Grid>
        </Grid>
        </Box>
        <ToastContainer></ToastContainer>
        </Container>
    )
}
export default Menus