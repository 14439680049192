import React, { useEffect } from 'react'
import CardModal from '../components/CardModal'
import { Box, Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useState } from 'react';
function Orders (){
  const sessionContext = useSessionContext();
        const navigate = useNavigate();
        const [permissionSet, setPermissionSet] = useState(false);
        const [permissions, setPermission] = useState([]);
        
        const roleCheckDis = async ()=>{
          try {
              const response = await  sessionContext.accessTokenPayload['st-perm'].v
              await setPermission(response)
              setPermissionSet(true);
            } catch (error) {
             
            }
          
        }
        useEffect(() => {
          if (permissionSet) {
              if (!permissions.includes('read:menu-item')) {
                  navigate('/home');
              }
          }
      }, [permissions, permissionSet]);
    useEffect(() => {
        roleCheckDis()
        fetchData()
      },[]);
      const fetchData = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_NGROK+'/order', {
            headers:{
              
            },
            params: {
            
            }
          });
          if(response.data){
            setMenuOrder(response.data);
          }
          else{
            console.log('Category Boş')
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };  
    const [garsons,setGarsonsData] =React.useState([])
    const tableInvites=[]
    const [order,setMenuOrder]=React.useState([])
   console.log(order)
    return(
        <>
        <Box sx={{mb:4}}>
            <Typography sx={{fontSize:30,fontWeight:'bold',mb:2}}>Garson Davetleri <Button variant='contained'>Yenile</Button></Typography>
            <Grid container spacing={2}>
            { garsons.map((item,index)=>(<Grid item xs={4}><CardModal notOrder='Garson Notu' masaNo={item.masaNo} order={item.discripton}></CardModal></Grid>))}
            </Grid>
        </Box>
        <Box sx={{mb:4}}>
            <Typography sx={{fontSize:30,fontWeight:'bold',mb:2}}>Rezervasyonlar <Button variant='contained'>Yenile</Button></Typography>
            <Grid container spacing={2}>
            { tableInvites.map((item,index)=>(<Grid item xs={4}><CardModal notOrder='Garson Notu' masaNo={item.masaNo} order={item.discripton}></CardModal></Grid>))}
            </Grid>
        </Box>
        <Box >
        <Typography sx={{fontSize:30,fontWeight:'bold',mb:2}}>Siparişler <Button onClick={fetchData} variant='contained'>Yenile</Button></Typography>
        <Grid container spacing={2}>
          { order.map((item,index)=>(<Grid item xs={2} sx={{minWidth:400,maxWidth:400}}><CardModal notOrder='Sipariş' fetchData={fetchData} id={item.id} stage={item.stage} masaNo={item.Table.tableAlias} order={item.TableOrders}deliveryInstructions={item.deliveryInstructions} orderNotes={item.kitchenInstructions} adress={item.address}></CardModal></Grid>))}
        </Grid>
        </Box>
        </>
    )
}
export default Orders