import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function AlergenSelectBox({ onSelectedOptionsChange }) {
    const [selectedOptions, setSelectedOptions] = useState([]);// Seçilen seçeneği saklayan state

  // Seçeneklerin listesi
  const options = [
    { value: 'YOK', name: 'Hayır' },
    { value: 'SHELLFISH', name: 'Kabuklu Deniz Ürünleri' },
    { value: 'MOLLUSK', name: 'Yumuşakçalar' },
    { value: 'FISH', name: 'Balık' },
    { value: 'TREE_NUT', name: 'Ağaç Fındıkları' },
    { value: 'PEANUT', name: 'Fıstık' },
    { value: 'GLUTEN', name: 'Gluten' },
    { value: 'POULTRY', name: 'Kümes Hayvanları' },
    { value: 'PORK', name: 'Domuz Eti' },
    { value: 'REDMEAT', name: 'Kırmızı Et' },
    { value: 'BOVINE', name: 'Sığır Et' },
    { value: 'MILK', name: 'Süt' },
    { value: 'EGG', name: 'Yumurta' },
    { value: 'MUSTARD', name: 'Hardal' },
    { value: 'SOY', name: 'Soya ' },
    { value: 'SESAME', name: 'Susam' },
    { value: 'SULFITE', name: 'Sülfürdioksit' },
    { value: 'CELERY', name: 'Kereviz' },
    { value: 'LUPIN', name: 'Yemlik Baklagil' },
    { value: 'COCONUT', name: 'Hindistancevizi' },
  ];

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedOptions(selectedValues); // Seçilen verileri yerel state'e kaydet

    // Seçilen verileri parent bileşene iletmek için onSelectedOptionsChange işlemini çağırın
    onSelectedOptionsChange(selectedValues);
  };

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="select-option"></InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        label=""
        inputProps={{
          name: 'option',
          id: 'select-option',
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}