import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import MenuSelectBox from "../../MenuSelectBox";
import axios from "axios";

export default function CategoryUpdateForm({ open, handleClose, id, errorFound, noError, searcData }) {
    const FormData = require("form-data");
    const form = new FormData();
    const [selectedMenuId, setSelectedMenuId] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [fileImage, setSelectedFileImage] = useState(null);
    const [urlS3, setUrl] = useState(null);
    const [fields, setFields] = useState(null);
    const [test, setTest] = useState(false);
    const [categorydiscription, setCategoryDiscription] = useState("");

    const handleMenuIdChange = (menuId) => {
        setSelectedMenuId(menuId);
       
    };

    const s3DataGet = () => {
        if (test) {
            console.log('Zaten açıksın');
        } else {
            setTest(true);
            axios.post(process.env.REACT_APP_NGROK + "/s3-bucket/upload", { fileName: 'Kategori 2' }, {
                headers: {
                    'Content-type': 'application/json'
                }
            }).then((response) => {
                
                setFields(response.data.fields);
                setUrl(response.data.url);
                setSelectedFileImage(response.data.uploadedTo);
            });
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        Object.entries(fields).forEach(([field, value]) => {
         
            form.append(field, value);
        });
        form.append("file", selectedFile);
        axios.post(urlS3, form, {
            headers: {
                'Content-type': 'image/jpeg'
            }
        }).then((response) => {
            
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid gray',
        borderRadius:5,
        boxShadow: 12,
        p: 4,
    };

    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_NGROK + "/category/" + id, {
                headers: {

                },
            });
            setCategoryName(response.data.name);
            setCategoryDiscription(response.data.description);
            handleMenuIdChange(response.data.Menu[0].id)
            setSelectedFileImage(response.data.image)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            let formData = {
                menuId: selectedMenuId,
                name: categoryName,
                description: categorydiscription,
                image: fileImage
            };

            const response = await axios.patch(process.env.REACT_APP_NGROK + "/category/" + id, JSON.stringify(formData), config);

            setCategoryName('');
            setCategoryDiscription('');
            setTest(false);
            noError();
            handleClose();

            // searcData() fonksiyonunu burada çağırabilirsiniz
            searcData();
        } catch (error) {
            // Hata oluştuysa
            setCategoryName('');
            setCategoryDiscription('');
            setTest(false);
            errorFound();
        }
    };

    const handleCloseModal = () => {
        setCategoryName('');
        setCategoryDiscription('');
        setSelectedFileImage(null);
        setSelectedMenuId('');
        setTest(false);
        handleClose(); // Diğer kapatma işlemlerini gerçekleştirin
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography sx={{ mb: 4, fontWeight: 'Bold' }}>Kategori Güncelleme Formu</Typography>
                

                <form onSubmit={handleFormSubmit}>
                    <MenuSelectBox selectedMenuId={selectedMenuId} onMenuIdChange={handleMenuIdChange}></MenuSelectBox>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Kategori  Adı"
                        onChange={e => setCategoryName(e.target.value)}
                        value={categoryName}
                        fullWidth
                        required
                        sx={{ mb: 4 }}
                    />
                    <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Kategori Açıklaması"
                        onChange={e => setCategoryDiscription(e.target.value)}
                        value={categorydiscription}
                        required
                        fullWidth
                        helperText="Açıklama 10 karakter ve üzeri olmak zorundadır"
                        sx={{ mb: 4 }}
                    />

                    <Button fullWidth variant="contained" color="primary" type="submit">Kategori Güncelleme</Button>
                     <Typography sx={{mt:3}} variant="h5" gutterBottom>
                        Kategori Fotoğraf  Yükleme Formu
                    </Typography> 
                    {fileImage?<Typography sx={{mt:3}} variant="h5" gutterBottom>
                        Kategori Fotoğrafı: <br />
                        <img style={{maxHeight:200,maxWidth:200 , textAlign:"center"}} src={fileImage}></img>
                    </Typography>:<Typography>Seçilmiş olan bir fotoğraf yok</Typography>} 
                    {test ? <input
                        style={{ marginBottom: 4 }}
                        accept="image/*"
                        type="file"
                        onChange={handleFileChange}
                    /> : <></>}
                </form>
                <Button onClick={s3DataGet} sx={{ mt: 4 }} fullWidth variant="contained" color="primary" type="submit">Fotoğraf Ekle</Button>
            </Box>
        </Modal>
    );
}