import * as React from 'react';
import { DataGrid   } from '@mui/x-data-grid';
import { Button } from '@mui/material';

export default function DataTable({colums, rows}) {
    
  return (
    
    <div style={{ height: 550, width: '100%' }}>
      <DataGrid
        columns={colums}
        rows={rows}

      />
    </div>
  );
}