import { InputLabel, MenuItem,  Select,FormControl } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export default function CategorySelectBox({ onMenuIdChange ,menuId}) {
    const [menuData,setMenuData]=React.useState(null)
    useEffect(() => {
        if (menuId !== null && menuId !== undefined) {
          const fetchData = async () => {
            try {
              const response = await axios.get(process.env.REACT_APP_NGROK+'/menu/'+menuId, {
                headers:{
                  //'ngrok-skip-browser-warning':'asd'
                },
                params: {
                  id: menuId,
                }
              });
              setMenuData(response.data.Categories);
              
            } catch (error) {
              
            }
          };
      
          fetchData();
        }
      }, [menuId]);
    const [categoryId, setCategoryId] = React.useState('');
    const handleChange = (event) => {
        const selectedMenuId = event.target.value;
        setCategoryId(event.target.value);
        onMenuIdChange(selectedMenuId); 
    };
    
    return (
        <div>
            <FormControl variant="standard" sx={{ width: '100%', mb: 2 }}>
        <InputLabel id="demo-simple-select-label-category">Kategori Seçiniz</InputLabel>
        <Select
          sx={{width:'100%',mb:4}}
          labelId="demo-simple-select-label-category"
          id="demo-simple-select-category"
          autoWidth
          value={categoryId}
          required
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {menuData ? (
            menuData.map((menu) => (
              <MenuItem key={menu.id} value={menu.id}>
                {menu.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Veri yükleniyor...</MenuItem>
          )}
        </Select>
        </FormControl>
      </div>
    );
  }