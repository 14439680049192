import { Box, Button, Container, Grid, Typography } from "@mui/material";
import '../App.css'
import React, { useEffect, useState } from "react";
import DataTable from "../components/Datatable";
import axios from "axios";
import DateSelectBox from "../components/DateSelectbox";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

function Category (){
        //Take Data
        const sessionContext = useSessionContext();
        const [permissionSet, setPermissionSet] = useState(false);
        const [permissions, setPermission] = useState([]);
        const navigate = useNavigate();
        const roleCheckDis = async ()=>{
          try {
              const response = await  sessionContext.accessTokenPayload['st-perm'].v
              await setPermission(response)
              setPermissionSet(true);
            } catch (error) {
             
            }
          
        }
        const fetchData = async () => {
          try {
            const response = await axios.get(process.env.REACT_APP_NGROK+'/order', {
              headers:{
            
              },
              params: {
                date: date,
              }
            });
            if(response.data){
                setOrderData(response.data);
              
            }
            else{
              console.log('Category Boş')
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        useEffect(() => {
          if (permissionSet) {
              if (!permissions.includes('read:order')) {
                  navigate('/home');
              }
          }
      }, [permissions, permissionSet]);
        useEffect(() => {
          roleCheckDis()
          fetchData();
        },[]);
    //DATAS
    const [orderData, setOrderData] = React.useState([]);
    const colums = [
        { field: 'id',headerName:'ID',width:200 , headerAlign: 'center',  align:'center'},
        { field: 'date',headerName:'Date',width:200 , headerAlign: 'center',  align:'center'},
        { field: 'name' ,headerName:'Masa no',width:200,headerAlign: 'center',align:'center'},
        { field: 'order',headerName:'Sipariş İçeriği',width:500,headerAlign: 'center',align:'center'},
        { field: 'price',headerName:'Fiyat',width:500,headerAlign: 'center',align:'center'},
      ]
    
    //Functions
    const [date,setDate] = React.useState();
    const handleMenuIdChange = (date) => {
      setDate(date);
    };  
      const searchData =()=>{
        fetchData()
      }
      const clearData=()=>{
        setDate('')
      }  
    return(
        <>
        <Container maxWidth="xl" sx={{backgroundColor:'#f2f5f7'  ,height: '100vh'}}>
        <Typography mt={3} variant="h3">Sipariş Kayıtları</Typography>
        <div style={{height:2,width:500,backgroundColor:'#ffa500' ,marginTop:10,marginBottom:5}}></div>
        <Box mt={3} sx={{ p: 2,border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'}}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
             <DateSelectBox onDataIdChange={handleMenuIdChange} ></DateSelectBox>
            </Grid>
            <Grid item xs={4}>
                <div className="buttonGroup">
                 <Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40 ,backgroundColor:'#386080'}} onClick={searchData} variant="contained" >Ara</Button>
                 <Button sx={{ minWidth: 240,marginTop:2 ,marginRight:2,height:40,backgroundColor:'#fccc74'}} onClick={clearData}variant="contained" >Temizle</Button>
                </div>
        
            </Grid>

        </Grid>
        </Box>
        <Box mt={6} sx={{ p: 2,border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'}}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <DataTable colums={colums} rows={orderData}></DataTable>
    
            </Grid>
        </Grid>
        </Box>
        
        </Container>
        </>
    )
}
export default Category