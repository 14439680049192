import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";

export default function FoodModificationForm({ open, handleClose, id ,errorFound,noError}) {
  const initialStateNameLan = {
    engName: "",
    ruName: "",
    almName: "",
  };
    const [stateNameLan, setStateNameLan] = useState(initialStateNameLan);
    const [openLan, setOpenLan] = useState(false)
    const handleCloseLan = () => setOpenLan(false);
    const handleOpenLan = () => {
      setOpenLan(true)
    }
    
    const handleChangeLan = (name, value) => {
      setStateNameLan((prevState) => ({ ...prevState, [name]: value }));
      
    };
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid gray',
        borderRadius:5,
        boxShadow: 12,
        p: 4,
      };
    const config = {
        headers:{
          'Content-type': 'application/json'
        }
      };
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const formData ={
        menuItemId:id,
        name:name,
        price: Number(price),
        type:'PRIVATE',
        description:description,
        nameLocals : [
          { lang: 'en_US', field: stateNameLan.engName },
          { lang: 'ru_ru', field: stateNameLan.ruName },
          { lang: 'de_de', field: stateNameLan.almName }
      ],
      }

      await axios.post(
       process.env.REACT_APP_NGROK + '/modification/',
        JSON.stringify(formData),
        config
      );

    

    noError();
    handleClose();
    } catch (error) {
        // Hata oluştuysa
        errorFound();
    }
}
    return(
    
    <>
    <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography sx={{ mb: 4, fontWeight: "Bold" }}>Modifikasyon Ekleme Formu</Typography>
          <Typography sx={{ mb: 4, fontWeight: "Bold" }}>Ürün Id: {id}</Typography>
          <form  onSubmit={handleSubmit}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Modifikasyon Adı"
              onChange={(e) => setName(e.target.value)}
              value={name}
              fullWidth
              required
              sx={{ mb: 4 }}
            />
            <Button sx={{ mb: 4, backgroundColor: '#386080' ,height:'55px'}} fullWidth variant="contained" color="primary" onClick={handleOpenLan}>Dil Ekle</Button>
            <TextField
              type="number"
              variant="outlined"
              color="secondary"
              label="Modifikasyon Fiyatı"
              onChange={e => setPrice(e.target.value)}
              value={price}
              fullWidth
              required
              sx={{ mb: 4 }}
            />
              <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Modifikasyon Açıklaması"
              onChange={e => setDescription(e.target.value)}
              value={description}
              fullWidth
              required
              sx={{ mb: 4 }}
            />
              <Button
              type="submit"
              sx={{ mb: 4, backgroundColor: "#386080" }}
              fullWidth
              variant="contained"
              color="primary"
            >
              Kaydet
            </Button>
          </form>
   
        </Box>
      </Modal>
      <Modal
        open={openLan}
        onClose={handleCloseLan}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ mb: 4, fontWeight: 'Bold' }}>Ürün Adı Dil Çeviri Ekleme</Typography>
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="İngilizce Adı"
            onChange={e => handleChangeLan("engName", e.target.value)}
            value={stateNameLan.engName}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="Almanca Adı"
            onChange={e => handleChangeLan("almName", e.target.value)}
            value={stateNameLan.almName}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="text"
            variant='outlined'
            color='secondary'
            label="Rusça Adı"
            onChange={e => handleChangeLan("ruName", e.target.value)}
            value={stateNameLan.ruName}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
        <Button fullWidth variant="contained" color="primary" onClick={handleCloseLan}>Onayla</Button>
        </Box>
      </Modal>
    
    
    </>
    
    )
}