
import './App.css';
import { Routes, HashRouter as Router, Route } from "react-router-dom";
import SideBar from './components/SideBar';
import Home from './Pages/Home';
import Menus from './Pages/Menus';
import OrdersPage from './Pages/OrdersPage'
import Orders from './Pages/Orders';
import * as reactRouterDom from "react-router-dom";

import MenuItem from './Pages/MenuItem';
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session, { SessionAuth,   } from "supertokens-auth-react/recipe/session";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Category from './Pages/Category';


SuperTokens.init({
    appInfo: {
        appName: "myapp",
        apiDomain: process.env.REACT_APP_NGROK,
        websiteDomain: process.env.REACT_APP_LOGIN_WEB,
        apiBasePath: "/auth",
        websiteBasePath: "/auth",
    },
    recipeList: [
        Session.init(
            {
                tokenTransferMethod: "header"
            }
        ),
        EmailPassword.init({
            signInAndUpFeature:{
                signInForm: {
                    style: `
                        [data-supertokens~=headerSubtitle] {
                            display: none;
                        }
                    `,
                }
                
            },
            
                override: {
                    apis: (originalImplementation) => {
                        return {
                            ...originalImplementation,
                            signUpPOST: undefined,
                        }
                    }
                },
                
                style: `
                [data-supertokens~=container] {
                    margin-top:100px;
                    --palette-background: 51, 51, 51;
                    --palette-inputBackground: 41, 41, 41;
                    --palette-inputBorder: 41, 41, 41;
                    --palette-textTitle: 255, 255, 255;
                    --palette-textLabel: 255, 255, 255;
                    --palette-textPrimary: 255, 255, 255;
                    --palette-error: 173, 46, 46;
                    --palette-textInput: 169, 169, 169;
                    --palette-textLink: 169, 169, 169;
                }
                [data-supertokens~="superTokensBranding"] {
                    display:none
                }
           

            `
            }
        ),
    ],
});

function App() {


    return(
        <SuperTokensWrapper>
            <div className="App app-container">
          
                <Router>
                    <div className="fill">
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI])}
                            
                            <Route
                                path="/"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <SideBar>
                                     <Home></Home>
                                     </SideBar>
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/home"
                             element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <SideBar>
                                     <Home></Home>
                                     </SideBar>
                                    </SessionAuth>
                                }
                            />
                             <Route
                                path="/menus"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                  <SessionAuth>
                                    <SideBar>
                                    <Menus></Menus>
                                    </SideBar>
                                    </SessionAuth>
                                }
                            />
                                 <Route
                                path="/category"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth  >
                                    <SideBar>
                                    <Category ></Category>
                                    </SideBar>
                                    </SessionAuth>
                                }
                            />
                                 <Route
                                path="/orders"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                    <SideBar>
                                    <Orders ></Orders>
                                    </SideBar>
                                    </SessionAuth>
                                }
                            />
                                   <Route
                                path="/orderspage"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                    <SideBar>
                                    <OrdersPage ></OrdersPage>
                                    </SideBar>
                                    </SessionAuth>
                                }
                            />
                                   <Route
                                path="/items"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth >
                                    <SideBar>
                                    <MenuItem ></MenuItem>
                                    </SideBar>
                                    </SessionAuth>
                                }
                            />
                           
                        </Routes>
                    </div>
                </Router>

            </div>
        </SuperTokensWrapper>
    
    )

}

export default App;
