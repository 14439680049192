import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import FoodUpdateForm from '../components/forms/Food/FoodUpdateForm'
import FoodCreateForm from '../components/forms/Food/FoodCreateForm'
import React, { useEffect, useRef, useState } from "react";
import DataTable from "../components/Datatable";
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MenuSelectBox from "../components/MenuSelectBox";
import CategorySelectBox from "../components/CategorySelectBox";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useNavigate } from "react-router-dom";
import FoodModificationForm from "../components/forms/Food/FoodModificationForm";

function MenuItem() {
  const [permissions, setPermission] = useState([]);
  const sessionContext = useSessionContext();
  const [permissionSet, setPermissionSet] = useState(false);
    const navigate = useNavigate();
  const [menuCheck,setMenuCheck]=useState(true)
  const menuSelectBoxRef = useRef(null);
  const roleCheckDis = async ()=>{
    try {
        const response = await  sessionContext.accessTokenPayload['st-perm'].v
        await setPermission(response)
        setPermissionSet(true);
      } catch (error) {
       
      }
    
  }
  const getData=()=>{
    if(selectedMenuId){
      fetchData()
    }
    else{
      fetchDataWithoutId()
    }
  }
  //Take Data
  const errorFound =()=>{
    toast.error('Bir Hata ile Karşılaşıldı', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  const noError = ()=>{
    toast.success('Ürün  Oluşturuldu!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  const fetchDataUseEffect = async () => {
    if(menuCheck){
      try {
        const response = await axios.post(process.env.REACT_APP_NGROK+'/menu-item/find-all', {
          params: {
            menuId: selectedMenuId,
            categoryId:selectedCategoryId
          }
        },{
          headers:{
            //'ngrok-skip-browser-warning':'asd'
          },
        } );
        setMenuData(response.data)
        setMenuCheck(false)
      } catch (error) {
        
      }
    }

  };
  const fetchDataWithoutId = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_NGROK+'/menu-item/find-all/', {
        params: {
         
        }
      },{
        headers:{
          //'ngrok-skip-browser-warning':'asd'
        },
      } );
      setMenuData(response.data)
    } catch (error) {
     
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_NGROK+'/category/'+selectedCategoryId, {
        params: {
         
        }
      },{
        headers:{
          //'ngrok-skip-browser-warning':'asd'
        },
      } );
      setMenuData(response.data.MenuItems)
    } catch (error) {
     
    }
  };
  useEffect(() => {
    if (permissionSet) {
        if (!permissions.includes('read:menu-item')) {
            navigate('/home');
        }
    }
}, [permissions, permissionSet]);
  useEffect(() => {
    roleCheckDis()
    fetchDataUseEffect();
  },[]);
  //DATAS
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const handleMenuIdChange = (menuId) => {
    setSelectedMenuId(menuId);
  };
  const handleCategoyIdChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const  modefication =()=>{

  }
  const [menuData, setMenuData] = React.useState([]);
  const colums = [
    { field: 'id', headerName: 'ID', width: 200, headerAlign: 'center', align: 'center' },
    { field: 'name', headerName: 'Ürün  Adı', width: 200, headerAlign: 'center', align: 'center' },
    { field: 'description', headerName: 'Ürün açıklaması', width: 300, headerAlign: 'center', align: 'center' },
    { field: 'price', headerName: 'Fiyat', width: 100, headerAlign: 'center', align: 'center' },
    {
      field: "action",
      width: 150,
      align: 'center',
      headerName: "Ürün Güncelle",
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) =>
       <Button onClick={() => handleOpen(row)}>
          <EditIcon />
        </Button>
    },
    {
      field: "modification",
      width: 150,
      align: 'center',
      headerName: "Modifikasyon Gir",
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) =>
       <Button onClick={() => handleNotificaiton(row)}>
          <AddIcon />
        </Button>
    },
    {
      field: "delete",
      width: 150,
      align: 'center',
      headerName: "Delete",
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) =>
      <Button onClick={() => deleteFunction(row.id)}>
          <RemoveIcon></RemoveIcon>
        </Button>
    },
  ]
  const [openNew, setOpenNew] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModification, setOpenModification] = React.useState(false);
  const [modId,setModId]=React.useState(0);
  const [menuId, SetMenuId] = React.useState(0);
  const handleClose = () => {
    setOpen(false);
    getData()
   
  }
 const handleNotificaiton = (e) => {
    setOpenModification(true);
    setModId(e.id)
   
  }
  const handleOpen = (e) => {
    setOpen(true)
    SetMenuId(e.id)
  }
  const searchData = () => {
    getData()
  }
  const deleteFunction = async (e) => {
    try {
        const response = await axios.delete(process.env.REACT_APP_NGROK + `/menu-item/` + e);

        if (response.error) {
            toast.error('Bir Hata ile Karşılaşıldı!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.success('Menü Silindi!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    } catch (error) {
        // Hata oluştuysa
    } finally {
        searchData();
    }
};
  const clearData = () => {
    setSelectedMenuId('')
    setSelectedCategoryId('')
    if (menuSelectBoxRef.current) {
      menuSelectBoxRef.current.clearMenuId();
    }
  }
  const handleNotificaitonClose = () => {
    setOpenModification(false)
    searchData()
  };
  const handleCloseNew = () => {
    setOpenNew(false)
    searchData()
  };
  const handleOpenNew = (e) => {
    setOpenNew(true)
    SetMenuId(e.id)
  }
  return (
    <Container maxWidth="xl" sx={{ backgroundColor: '#f2f5f7', height: '100vh' }}>
      <Typography mt={3} variant="h3">Ürünler</Typography>
      <div style={{height:2,width:500,backgroundColor:'#ffa500' ,marginTop:10,marginBottom:5}}></div>
      <Box mt={3} sx={{ p: 2, border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'  }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <MenuSelectBox ref={menuSelectBoxRef} onMenuIdChange={handleMenuIdChange}></MenuSelectBox>
            <CategorySelectBox onMenuIdChange={handleCategoyIdChange} menuId={selectedMenuId}></CategorySelectBox>
          </Grid>
          <Grid item xs={4}>
            <div className="buttonGroup">
            {permissions.includes('create:menu-item')?  <Button sx={{ minWidth: 240, marginTop: 2, marginRight: 2, height: 40, backgroundColor: '#217afa' }} onClick={handleOpenNew} variant="contained" >Yeni Ürün</Button> :<Button sx={{ minWidth: 240, marginTop: 2, marginRight: 2, height: 40, backgroundColor: '#217afa' }} onClick={handleOpenNew} variant="contained" >Yeni Ürün</Button>}
              <Button sx={{ minWidth: 240, marginTop: 2, marginRight: 2, height: 40, backgroundColor: '#386080' }} onClick={searchData} variant="contained" >Ara</Button>
              <Button sx={{ minWidth: 240, marginTop: 2, marginRight: 2, height: 40, backgroundColor: '#fccc74' }} onClick={clearData} variant="contained" >Temizle</Button>

            </div>

          </Grid>

        </Grid>
      </Box>
      <Box mt={6} sx={{ p: 2, border: '1px outset grey' ,backgroundColor:'white' ,borderRadius:5,boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'  }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DataTable colums={colums} rows={menuData}></DataTable>
            <FoodUpdateForm open={open} id={menuId} handleClose={handleClose} errorFound={errorFound} noError={noError} ></FoodUpdateForm>
            <FoodCreateForm open={openNew} handleClose={handleCloseNew}  errorFound={errorFound} noError={noError}></FoodCreateForm>
            <FoodModificationForm id={modId} open={openModification} handleClose={handleNotificaitonClose} errorFound={errorFound} noError={noError}></FoodModificationForm>
          </Grid>
        </Grid>
      </Box>
    <ToastContainer/>
    </Container>
  )
}
export default MenuItem