import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import axios from 'axios';
export default function OutlinedCard({ masaNo, order ,orderNotes,adress,stage,id,fetchData,deliveryInstructions}) {
  const [check, setCheck] = React.useState(false);
  const turkceStageAdlari = {
    'REQUESTED': 'Talebi Onayla',
    'ACKNOWLEDGED': 'Hazırlamaya başla',
    'COOKING': 'Hazırlandı',
    'DELIVERING': 'Şiparişi Tamamla',
    'DONE': 'Tamamlandı'
  };
  const setStatus = async()=>{
    let stageUpdated=''
    if (stage === 'REQUESTED') {
      stageUpdated = 'ACKNOWLEDGED';
    } else if (stage === 'ACKNOWLEDGED') {
      stageUpdated = 'COOKING';
    } else if (stage === 'COOKING') {
      stageUpdated = 'DELIVERING';
    } else if (stage === 'DELIVERING') {
      stageUpdated = 'DONE';
    }
    try {
      const response = await axios.patch(process.env.REACT_APP_NGROK+'/order/'+id,{stage:stageUpdated},{
        headers:{
          
        },
        params: {
        
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    fetchData()
  }
  const cardContent = (
    <CardContent>
      {masaNo ? (
        <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
          Masa NO : {masaNo} 
        </Typography>
      ) : (
        <Typography></Typography>
      )}
      <div style={{marginTop:30}}>
      {order.map((item, index) => (
        <div  key={index}>
          <Typography >{item.quantity}x {item.MenuItem.name}</Typography>
        </div>
      ))}
      </div>
      <div>
      <Typography style={{marginTop:30}} >Açık Adres</Typography>
        {adress}
      </div>
      <div>
      <Typography style={{marginTop:30}} >Sipariş Notu:</Typography>
        {orderNotes?orderNotes:<>-</>}
      </div>
      <div>
      <Typography style={{marginTop:30}} >Genel Notlar:</Typography>
        {deliveryInstructions?deliveryInstructions:<>-</>}
      </div>
      
      <Button fullWidth variant="contained" onClick={setStatus} sx={{ marginTop: 4 }}>
      {turkceStageAdlari[stage] || stage}
      </Button>
      <Typography sx={{ fontSize: 12, color: 'text.secondary', marginTop: 2 }}>
        * Siparişlerin statülerini değiştirmek  için  butonu kullanabilirsiniz.
      </Typography>
    </CardContent>
  );

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card sx={{ borderBottom: '2px outset grey' }} variant="outlined">
        {cardContent}
      </Card>
    </Box>
  );
}